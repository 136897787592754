//
// Copyright 2024 DXOS.org
//

// @generated by protoc-gen-es v2.1.0 with parameter "target=ts,import_extension=js"
// @generated from file dxos/edge/calls.proto (package dxos.edge.calls, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dxos_keys } from "../keys_pb.js";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file dxos/edge/calls.proto.
 */
export const file_dxos_edge_calls: GenFile = /*@__PURE__*/
  fileDesc("ChVkeG9zL2VkZ2UvY2FsbHMucHJvdG8SD2R4b3MuZWRnZS5jYWxscyKEAgoGVHJhY2tzEhIKBWF1ZGlvGAEgASgJSACIAQESGgoNYXVkaW9fZW5hYmxlZBgCIAEoCEgBiAEBEhIKBXZpZGVvGAMgASgJSAKIAQESGgoNdmlkZW9fZW5hYmxlZBgEIAEoCEgDiAEBEhgKC3NjcmVlbnNoYXJlGAUgASgJSASIAQESIAoTc2NyZWVuc2hhcmVfZW5hYmxlZBgGIAEoCEgFiAEBQggKBl9hdWRpb0IQCg5fYXVkaW9fZW5hYmxlZEIICgZfdmlkZW9CEAoOX3ZpZGVvX2VuYWJsZWRCDgoMX3NjcmVlbnNoYXJlQhYKFF9zY3JlZW5zaGFyZV9lbmFibGVkIpMCCglVc2VyU3RhdGUSCgoCaWQYASABKAkSDAoEbmFtZRgCIAEoCRIjChZ0cmFuc2NlaXZlcl9zZXNzaW9uX2lkGAMgASgJSACIAQESEwoLcmFpc2VkX2hhbmQYBCABKAgSEAoIc3BlYWtpbmcYBSABKAgSDgoGam9pbmVkGAYgASgIEicKBnRyYWNrcxgHIAEoCzIXLmR4b3MuZWRnZS5jYWxscy5UcmFja3MSOgoNdHJhbnNjcmlwdGlvbhgIIAEoCzIeLmR4b3MuZWRnZS5jYWxscy5UcmFuc2NyaXB0aW9uSAGIAQFCGQoXX3RyYW5zY2VpdmVyX3Nlc3Npb25faWRCEAoOX3RyYW5zY3JpcHRpb24icQoNVHJhbnNjcmlwdGlvbhIPCgdlbmFibGVkGAEgASgIEjwKEWxhbXBvcnRfdGltZXN0YW1wGAIgASgLMiEuZHhvcy5lZGdlLmNhbGxzLkxhbXBvcnRUaW1lc3RhbXASEQoJcXVldWVfZHhuGAMgASgJIi8KEExhbXBvcnRUaW1lc3RhbXASDwoHdmVyc2lvbhgBIAEoBRIKCgJpZBgCIAEoCWIGcHJvdG8z", [file_dxos_keys]);

/**
 * @generated from message dxos.edge.calls.Tracks
 */
export type Tracks = Message<"dxos.edge.calls.Tracks"> & {
  /**
   * @generated from field: optional string audio = 1;
   */
  audio?: string;

  /**
   * @generated from field: optional bool audio_enabled = 2;
   */
  audioEnabled?: boolean;

  /**
   * @generated from field: optional string video = 3;
   */
  video?: string;

  /**
   * @generated from field: optional bool video_enabled = 4;
   */
  videoEnabled?: boolean;

  /**
   * @generated from field: optional string screenshare = 5;
   */
  screenshare?: string;

  /**
   * @generated from field: optional bool screenshare_enabled = 6;
   */
  screenshareEnabled?: boolean;
};

/**
 * Describes the message dxos.edge.calls.Tracks.
 * Use `create(TracksSchema)` to create a new message.
 */
export const TracksSchema: GenMessage<Tracks> = /*@__PURE__*/
  messageDesc(file_dxos_edge_calls, 0);

/**
 * @generated from message dxos.edge.calls.UserState
 */
export type UserState = Message<"dxos.edge.calls.UserState"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string name = 2;
   */
  name: string;

  /**
   * @generated from field: optional string transceiver_session_id = 3;
   */
  transceiverSessionId?: string;

  /**
   * @generated from field: bool raised_hand = 4;
   */
  raisedHand: boolean;

  /**
   * @generated from field: bool speaking = 5;
   */
  speaking: boolean;

  /**
   * @generated from field: bool joined = 6;
   */
  joined: boolean;

  /**
   * @generated from field: dxos.edge.calls.Tracks tracks = 7;
   */
  tracks?: Tracks;

  /**
   * @generated from field: optional dxos.edge.calls.Transcription transcription = 8;
   */
  transcription?: Transcription;
};

/**
 * Describes the message dxos.edge.calls.UserState.
 * Use `create(UserStateSchema)` to create a new message.
 */
export const UserStateSchema: GenMessage<UserState> = /*@__PURE__*/
  messageDesc(file_dxos_edge_calls, 1);

/**
 * *
 * Transcription settings.
 * It will be merged across all users in the room based on the Lamport timestamp (last one wins).
 *
 * @generated from message dxos.edge.calls.Transcription
 */
export type Transcription = Message<"dxos.edge.calls.Transcription"> & {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled: boolean;

  /**
   * @generated from field: dxos.edge.calls.LamportTimestamp lamport_timestamp = 2;
   */
  lamportTimestamp?: LamportTimestamp;

  /**
   * *
   * Transcription queue DXN.
   *
   * @generated from field: string queue_dxn = 3;
   */
  queueDxn: string;
};

/**
 * Describes the message dxos.edge.calls.Transcription.
 * Use `create(TranscriptionSchema)` to create a new message.
 */
export const TranscriptionSchema: GenMessage<Transcription> = /*@__PURE__*/
  messageDesc(file_dxos_edge_calls, 2);

/**
 * *
 * Lamport Timestamp.
 * @see https://en.wikipedia.org/wiki/Lamport_timestamp
 *
 * @generated from message dxos.edge.calls.LamportTimestamp
 */
export type LamportTimestamp = Message<"dxos.edge.calls.LamportTimestamp"> & {
  /**
   * @generated from field: int32 version = 1;
   */
  version: number;

  /**
   * @generated from field: string id = 2;
   */
  id: string;
};

/**
 * Describes the message dxos.edge.calls.LamportTimestamp.
 * Use `create(LamportTimestampSchema)` to create a new message.
 */
export const LamportTimestampSchema: GenMessage<LamportTimestamp> = /*@__PURE__*/
  messageDesc(file_dxos_edge_calls, 3);

